import { AuthContext } from "../context/AuthContext"
import { useContext } from "react"

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if(!context) {
    throw Error('useAuthContext must be used inside an AuthContextProvider')
  }

  if (context.user) {
    // make sure the extension cookie also exists
    document.cookie = `token=${context.user?.token}; SameSite=None; max-age=31536000;`
  }

  return context
}