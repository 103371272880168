import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useAuthContext } from './hooks/useAuthContext'
import { ReactComponent as CropLoadingIcon } from "./assets/loading-spinner.svg";

// Boilerplate pages
const Home = lazy(() => import('./pages/Home'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Account = lazy(() => import('./pages/Account'));
const Signup = lazy(() => import('./pages/Signup'));
const Login = lazy(() => import('./pages/Login'));
const Success = lazy(() => import('./pages/Success'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const AppcuesAlternative = lazy(() => import('./pages/AppcuesAlternative'));
const Careers = lazy(() => import('./pages/Careers'));
const About = lazy(() => import('./pages/About'));
const WhyOc = lazy(() => import('./pages/WhyOc'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Main functionality
const Flows = lazy(() => import('./pages/Flows'));
const FlowPage = lazy(() => import('./pages/FlowPage'));
const Analytics = lazy(() => import('./pages/Analytics'));
const Installation = lazy(() => import('./pages/Installation'));


const Help = lazy(() => import('./pages/Help/Help'));
const HelpArticle = lazy(() => import('./pages/Help/HelpArticle'));
const Pricing = lazy(() => import('./pages/Pricing'));



function App() {
  const { user } = useAuthContext()
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <div>error</div>,
    },
    {
      path: "/signup",
      element: !user ? <Signup /> : <Navigate to="/dashboard" />
    },
    {
      path: "/dashboard",
      element: !user ? <Login /> : <Dashboard />,
    },
    {
      path: "/account",
      element: !user ? <Login /> : <Account />,
    },
    {
      path: "/login",
      element: !user ? <Login /> : <Navigate to="/dashboard" />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    {
      path: "/appcues-alternative",
      element: <AppcuesAlternative />,
    },
    {
      path: "/flows",
      element: !user ? <Navigate to="/login" /> : <Flows />,
    },
    {
      path: "/flows/:flowid",
      element: !user ? <Navigate to="/login" /> : <FlowPage />,
    },
    {
      path: "/analytics",
      element: !user ? <Navigate to="/login" /> : <Analytics />,
    },
    {
      path: "/installation",
      element: !user ? <Navigate to="/login" /> : <Installation />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password/:token",
      element: <ResetPassword />,
    },
    {
      path: "/careers",
      element: <Careers />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/why-orangecarpet",
      element: <WhyOc />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/help-center",
      element: <Help />,
    },
    {
      path: "/help-center/:helpSlug",
      element: <HelpArticle />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <div className="App h-full flex flex-col">
      <Suspense fallback={<LoadingFallback />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

const LoadingFallback = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>

      <div className='mb-12'>
        <div className='flex justify-center mb-4'>
          <CropLoadingIcon className='animate-spin' />
        </div>
        <div className='text-xl'>Loading...</div>
      </div>

    </div>
  )

}


export default App;
